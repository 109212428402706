/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactForm() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		service: '',
		message: ''
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Replace with your EmailJS user ID and service/template IDs
		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
		const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
		const userID = process.env.REACT_APP_EMAILJS_USER_ID;

		emailjs.send(serviceID, templateID, formData, userID)
			.then((response) => {
				console.log('Email sent successfully:', response);
				setFormData({ name: '', email: '', service: '', message: '' }); // Clear the form
			})
			.catch((error) => {
				console.error('Error sending email:', error);
			});
	};

	return (
		<div className="d-block offwhite-bg py-5 row text-center">
			<div className="col-12 p-0 text-black">
				<h1 className="main-heading montserrat-text">Request a Free Quote</h1>
				<div>
					<form className="lg-margin xl-padding" onSubmit={handleSubmit}>
						<div>
							<label>Name:</label>
							<input 
								type="text" 
								name="name" 
								value={formData.name} 
								onChange={handleChange} 
								required 
							/>
						</div>
						<div>
							<label>Email:</label>
							<input 
								type="email" 
								name="email" 
								value={formData.email} 
								onChange={handleChange} 
								required 
							/>
						</div>
						<div>
							<label>Service:</label>
							<select 
								name="service" 
								value={formData.service} 
								onChange={handleChange} 
								required
							>
								<option value="" disabled>
									Select a service
								</option>
								<option value="custom software">Custom Software</option>
								<option value="e-commerce website">E-commerce Website</option>
								<option value="logo design">Logo Design</option>
								<option value="professional video">Professional Video</option>
								<option value="wedding invites">Wedding Invites</option>
								<option value="business cards">Business Cards</option>
								<option value="portfolio site">Portfolio Site</option>
								<option value="business website">Business Website</option>
							</select>
						</div>
						<div>
							<label>Message:</label>
							<textarea 
								name="message" 
								value={formData.message} 
								onChange={handleChange} 
								required 
							/>
						</div>
						<button type="submit" className="btn btn-success my-3 orange-button">Send Request</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default ContactForm;
